import React from 'react'
import { graphql } from 'gatsby'
import { IndexPageTemplate } from './index-page'
import Layout from '../components/Layout'

const ServicePost = ({ data, location }) => {
  if (!data) {
    return null
  }

  const { frontmatter } = data.markdownRemark

  console.log(111)
  return (
    <Layout>
      <IndexPageTemplate
        sections={[
          ...frontmatter.sections,
          {
            backgroundImage: '',
            fontColor: 'black',
            columns: [
              {
                contents: [
                  {
                    type: 'text',
                    tag: 'h1',
                    body: '서비스 목록을 확인하세요.',
                  },
                  {
                    type: 'service-list',
                  },
                ],
              },
            ],
          },
        ]}
        pathname={location.pathname}
      />
    </Layout>
  )
}

export default ServicePost

export const pageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...IndexPostFragment
    }
  }
`
